import React from 'react';
import {
  StackLayout,
  Card,
  ParagraphText,
  Button,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface AddressCardProps {
  titleId?: string;
  addressLabelId: string;
  address: string;
  showEdit?: boolean;
  isHighlighted?: boolean;
  onEdit?: () => void;
}

const AddressCardV2: React.FC<AddressCardProps> = ({
  titleId,
  addressLabelId,
  address,
  isHighlighted = false,
  showEdit = false,
  onEdit,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  // Example of splitting the address lines on mobile
  // This is a simplistic approach; adjust splitting logic as needed.
  const addressLines = address.split(',').map((line) => line.trim());

  return (
    <>
      {isMobile && titleId && (
        <StackLayout
          orientation="horizontal"
          spacing="$one"
          css={{
            justifyContent: 'space-between',
            paddingX: '$one',
            marginTop: '$oneAndHalf',
            width: '100%',
            maxWidth: '888px',
            '@media (max-width: 600px)': { maxWidth: '100%' },
          }}
        >
          <ParagraphText
            css={{
              fontSize: '20px',
              fontWeight: '700',
              lineHeight: '28px',
            }}
          >
            {formatMessage({ id: titleId as never })}
          </ParagraphText>
          {showEdit && (
            <Button
              onClick={onEdit}
              priority="tertiary"
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
                '@tablet': 'hugContents',
              }}
              quiet
              css={{
                color: '$interactiveActionPrimary',
                padding: '0 !important',
                width: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'inherit',
                  boxShadow: 'none',
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {formatMessage({ id: 'STR_EDIT' })}
            </Button>
          )}
        </StackLayout>
      )}

      <Card.Elevated
        css={{
          padding: '24px',
          boxShadow: 'none',
          borderRadius: '9px',
          border: isHighlighted ? '1px solid #5C6370' : '1px solid #D6D6D6',
          width: '100%',
          maxWidth: '888px',
          backgroundColor: '$surfaceBackgroundPrimary',
          '@media (max-width: 600px)': { maxWidth: '100%' },
        }}
      >
        {!isMobile && titleId && (
          <StackLayout
            orientation="horizontal"
            spacing="$one"
            css={{
              justifyContent: 'space-between',
              marginBottom: '$oneAndHalf',
            }}
          >
            <ParagraphText
              css={{
                fontSize: '20px',
                fontWeight: '700',
                lineHeight: '28px',
              }}
            >
              {formatMessage({ id: titleId as never })}
            </ParagraphText>
            {showEdit && (
              <Button
                onClick={onEdit}
                priority="tertiary"
                width={{
                  '@initial': 'hugContents',
                  '@mobile': 'fillContainer',
                  '@tablet': 'hugContents',
                }}
                quiet
                css={{
                  color: '$interactiveActionPrimary',
                  padding: '0 !important',
                  width: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    boxShadow: 'none',
                  },
                  '&:focus': {
                    outline: 'none',
                  },
                }}
              >
                {formatMessage({ id: 'STR_EDIT' })}
              </Button>
            )}
          </StackLayout>
        )}

        <StackLayout
          orientation={isMobile ? 'vertical' : 'horizontal'}
          css={{ marginTop: isMobile ? 0 : '8px' }}
        >
          <ParagraphText
            size="xs"
            css={{
              fontSize: '14px',
            }}
          >
            {formatMessage({ id: addressLabelId as never })}
          </ParagraphText>

          <StackLayout
            css={{
              marginLeft: isMobile ? '0' : 'auto',
              textAlign: isMobile ? 'left' : 'right',
            }}
          >
            {!isMobile ? (
              <ParagraphText
                css={{
                  fontSize: '12px',
                  fontWeight: '700',
                  lineHeight: '16px',
                }}
              >
                {address}
              </ParagraphText>
            ) : (
              // On mobile: each part of address on its own line
              addressLines.map((line) => (
                <ParagraphText
                  key={line}
                  css={{
                    fontSize: '12px',
                    fontWeight: '700',
                    lineHeight: '16px',
                  }}
                >
                  {line}
                </ParagraphText>
              ))
            )}
          </StackLayout>
        </StackLayout>
      </Card.Elevated>
    </>
  );
};

export default AddressCardV2;
