import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { BasePage } from 'components/BasePage';
import { OnboardingFeatureHighlights } from 'components/onboarding-feature-highlights/onboarding-feature-highlights.component';
import { AccountSetupForm } from 'components/post-authentication/account-setup/account-setup.form.component';
import { AllSetPage } from 'components/post-authentication/all-set.component';
import { useOnboarding } from 'hooks/use-onboarding.hook';

export const PrivateRoutesContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    stage,
    isLoading,
    onboardingCompleted,
    onFeatureHighlightComplete,
    onAccountSetupComplete,
    onAllSetComplete,
    onFirstStep,
  } = useOnboarding();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  // If onboarding has been completed, show the main content directly
  if (onboardingCompleted) {
    return <BasePage>{children}</BasePage>;
  }

  // Otherwise, handle the onboarding stages
  switch (stage) {
    case 'featureHighlights':
      return (
        <OnboardingFeatureHighlights
          onOnboardingComplete={onFeatureHighlightComplete}
        />
      );
    case 'accountSetup':
      return (
        <AccountSetupForm
          onStageChange={onAccountSetupComplete}
          onBack={onFirstStep}
        />
      );
    case 'allSet':
      return <AllSetPage onCompleted={onAllSetComplete} />;
    case 'completed':
    default:
      return <BasePage>{children}</BasePage>;
  }
};
