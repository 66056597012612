import { useIntl } from '@leagueplatform/locales';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import { useDocumentTitle } from '@leagueplatform/web-common';

import { NotEligibleScreen } from 'components/error/not-eligible-screen.component';
import { ErrorTypes } from 'hooks/use-error-message.hook';

export const ContactUsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  useDocumentTitle(formatMessage({ id: 'STR_CONTACT_INFORMATION' }));

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: formatMessage({ id: 'STR_CONTACT_INFORMATION' }),
    details: 'user views the contact information page',
  });

  return <NotEligibleScreen errorType={ErrorTypes.NOT_ELIGIBLE} backLink="/" />;
};
