import React from 'react';
import {
  FormField,
  TextInput,
  Select,
  Box,
  useMediaQuery,
  queryHelpers,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface FieldConfig {
  id: string;
  labelId: string;
  defaultMessage: string;
  type: 'text' | 'select';
  required?: boolean;
  size?: number;
  options?: Array<{ label: string; value: string }>;
  validation?: (value: string) => boolean;
}

interface FormFieldsProps {
  formModel: FieldConfig[];
  isSelected: boolean;
  formData: Record<string, string>;
  onFieldChange: (fieldId: string, value: string) => void;
  errors?: Record<string, string>;
}

function getInputMaxWidth(fieldId: string, isMobile: boolean) {
  if (fieldId === 'zip') {
    return '292px';
  }

  if (isMobile) {
    return '292px';
  }

  return '100%';
}

const FormFields: React.FC<FormFieldsProps> = ({
  formModel,
  formData,
  isSelected,
  onFieldChange,
  errors,
}) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  return (
    <Box
      css={{
        display: 'grid',
        gridTemplateColumns: isMobile
          ? '1fr'
          : 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: isMobile ? '$two' : '$one',
        width: '90%',
        marginBottom: isMobile ? '20px' : '50px',
      }}
    >
      {formModel.map((field) => {
        const inputMaxWidth = getInputMaxWidth(field.id, isMobile);

        if (!field.required && !formData[field.id]) {
          return (
            <Box key={field.id}>
              <FormField
                id={field.id}
                label={formatMessage({
                  id: field.labelId as never,
                  defaultMessage: field.defaultMessage,
                })}
              >
                {field.type === 'text' ? (
                  <TextInput
                    value={formData[field.id]}
                    onChange={(e) => onFieldChange(field.id, e.target.value)}
                    css={{
                      width: '100%',
                      maxWidth: inputMaxWidth,
                    }}
                  />
                ) : (
                  <Select
                    options={field.options || []}
                    value={formData[field.id]}
                    onChange={(e: any) =>
                      onFieldChange(field.id, e.target.value)
                    }
                    css={{
                      width: '100%',
                      maxWidth: inputMaxWidth,
                    }}
                  />
                )}
              </FormField>
            </Box>
          );
        }

        const isFieldValid = field.validation
          ? field.validation(formData[field.id] || '')
          : !!formData[field.id];

        const errorMessage =
          !isFieldValid && field.required
            ? formatMessage({
                id: `STR_${field.id}_INVALID` as never,
              })
            : errors?.[field.id] || '';

        return (
          <Box key={field.id}>
            <FormField
              id={field.id}
              required={field.required}
              label={formatMessage({
                id: field.labelId as never,
              })}
              inputStatus={
                isSelected && (!isFieldValid || errors?.[field.id])
                  ? 'error'
                  : undefined
              }
              statusMessage={errorMessage}
            >
              {field.type === 'text' ? (
                <TextInput
                  value={formData[field.id]}
                  onChange={(e) => onFieldChange(field.id, e.target.value)}
                  inputStatus={
                    isSelected && (!isFieldValid || errors?.[field.id])
                      ? 'error'
                      : undefined
                  }
                  css={{
                    width: '100%',
                    maxWidth: inputMaxWidth,
                  }}
                />
              ) : (
                <Select
                  options={field.options || []}
                  value={formData[field.id]}
                  onChange={(e: any) => onFieldChange(field.id, e.target.value)}
                  css={{
                    width: '100%',
                    maxWidth: inputMaxWidth,
                  }}
                />
              )}
            </FormField>
          </Box>
        );
      })}
    </Box>
  );
};

export default FormFields;
