/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { RewardsEventController } from '@leagueplatform/rewards-events';
import { AchievementsRoutes } from '@leagueplatform/rewards-achievements';
import {
  AuthViewLogin,
  AuthViewLogout,
  AuthStartImpersonationSession,
} from 'pages/auth/Auth.pages';

import {
  LandingPage,
  CareCollectionsRoutes,
  // CareDiscoveryRoutes,
  HealthJourneyRoutes,
  HealthNeedPages,
  HealthProfileRoutes,
  AboutMeRoutes,
  Home,
  OnboardingRoutesWithProvider,
  WalletRoutes,
  ClaimsRoutes,
} from 'components/LazyRoutes';

import ErrorPage from 'pages/error-404';
import { PATHS } from 'commons/constants';
import { AboutUsPage } from 'pages/about-us/about-us.page';
import { FormsPage } from 'pages/forms/Forms.page';
import { ContactUsPage } from 'pages/contact-us/contact-us.page';
import { PersonalInformationPage } from 'pages/personal-information.page';
import { CommunicationPreferencesPage } from 'pages/communication-preferences/communication-preferences.page';
import { ChangePCPPage } from 'pages/change-pcp/change-pcp.page';
import { AuthorizationPage } from 'pages/authorizations/authorizations.page';
import { TempCareLandingPage } from 'pages/temporary-get-care-landing.page';
import { AuthorizationDetailPage } from 'pages/authorizations/authorizations-details.page';
import { ProviderRedirectPage } from 'pages/provider-redirect.page';
import useSyncRouteWithBrowser from 'hooks/use-sync-route-with-browser.hook';
import useConditionalNavigation from 'hooks/use-conditional-navigation.hook';
import { useInterceptLinkClick } from 'hooks/use-intercept-link-click.hook';
import { PrivateRoutesContainer } from './private-routes-container/private-routes-container.component';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';

function AppRoutes() {
  useSyncRouteWithBrowser();
  useConditionalNavigation();
  useInterceptLinkClick('/sso/vendors/', import.meta.env.VITE_LINKOUT_ROOT_URL);

  return (
    <Switch>
      <Route path="/sign-in" component={AuthViewLogin} />
      <Route path="/sign-out" component={AuthViewLogout} />
      <Route path="/start-login" component={AuthStartImpersonationSession} />
      <Route path="/" exact component={LandingPage} />
      <AuthSessionContainer>
        <PrivateRoutesContainer>
          <Switch>
            <Route path="/home" component={Home} exact key="home" />
            <Route
              path={[
                getModulePath(LEAGUE_MODULE_NAMES.healthJourney),
                getModulePath(LEAGUE_MODULE_NAMES.healthPrograms),
                getModulePath(LEAGUE_MODULE_NAMES.healthProgress),
                getModulePath(LEAGUE_MODULE_NAMES.healthJourneyHistory),
              ]}
              component={HealthJourneyRoutes}
              key="health-journey"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
              key="care-collections"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
              component={ClaimsRoutes}
              key="claims"
            />
            {/* <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={CareDiscoveryRoutes}
              key="care-discovery"
            /> */}
            {/* to be replaced after SCFHP-874-update-get-care-masonry has been merged */}
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={TempCareLandingPage}
              key="care-discovery"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.healthNeedPages)}
              component={HealthNeedPages}
              key="health-needs"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
              key="wallet"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.healthProfile)}
              component={HealthProfileRoutes}
              key="health-profile"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={AboutMeRoutes}
              key="about-me"
            />
            <Route path={PATHS.FORMS} component={FormsPage} key="forms" />
            <Route
              path={PATHS.CONTACT_US}
              component={ContactUsPage}
              key="contact-us"
              exact
            />
            <Route
              path={PATHS.PERSONAL_INFO}
              component={PersonalInformationPage}
            />
            <Route
              path={PATHS.COMMUNICATION_PREFERENCES}
              component={CommunicationPreferencesPage}
              exact
            />
            <Route path={PATHS.CHANGE_PCP} component={ChangePCPPage} exact />
            <Route
              path={PATHS.AUTH_STATUSES_PAGE}
              component={AuthorizationPage}
              exact
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.rewardsAchievements)}
              component={AchievementsRoutes}
              exact
            />

            <Route
              path={PATHS.AUTH_STATUS_PAGE}
              component={AuthorizationDetailPage}
              exact
            />
            <Route
              path="/provider-redirect"
              component={ProviderRedirectPage}
              exact
            />

            <Route path={PATHS.ABOUT} component={AboutUsPage} exact />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.onboarding)}
              component={OnboardingRoutesWithProvider}
              key="onboarding"
            />

            <Route component={ErrorPage} />
          </Switch>
          <RewardsEventController />
        </PrivateRoutesContainer>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
