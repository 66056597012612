import React from 'react';
import {
  ParagraphText,
  StackLayout,
  HeadingText,
  Card,
} from '@leagueplatform/genesis-core';

export interface AuthorizationServiceInfoSectionProps {
  heading: string;
  info: { key: string; value: string }[];
  isVisible: boolean;
}

export const AuthorizationServiceInfoSection: React.FC<
  AuthorizationServiceInfoSectionProps
> = ({ heading, info, isVisible }) => {
  if (!isVisible) return null;

  return (
    <>
      <HeadingText
        level="3"
        size="md"
        css={{
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '28px',
          marginTop: '$oneAndHalf',
        }}
      >
        {heading}
      </HeadingText>
      <Card.Elevated
        css={{
          display: 'flex',
          flexDirection: 'column',
          paddingY: '$half',
          boxShadow: 'none',
          borderRadius: '8px',
          backgroundColor: 'inherit',
          border: '1px solid #D6D6D6',
        }}
      >
        <StackLayout
          orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
          css={{ gap: '$one', alignItems: 'start', padding: '$two' }}
        >
          {info.map(({ key, value }) => (
            <StackLayout
              key={key}
              orientation="vertical"
              css={{
                padding: '$half',
              }}
            >
              <ParagraphText css={{ fontWeight: 700 }}>{key}</ParagraphText>
              <ParagraphText>{value}</ParagraphText>
            </StackLayout>
          ))}
        </StackLayout>
      </Card.Elevated>
    </>
  );
};
