export type AuthorizationStatus =
  | 'approved'
  | 'medReview'
  | 'inProgress'
  | 'closed'
  | 'denied';

export const getStatusTranslationKey = (
  status: AuthorizationStatus,
): string => {
  const translationKeys: Record<AuthorizationStatus, string> = {
    approved: 'STR_APPROVED',
    medReview: 'STR_MED_REVIEW',
    inProgress: 'STR_IN_PROGRESS',
    closed: 'STR_CLOSED',
    denied: 'STR_DENIED',
  };

  return translationKeys[status];
};
