import React from 'react';
import {
  StackLayout,
  Card,
  ParagraphText,
  Button,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface AddressCardProps {
  titleId?: string;
  addressLabelId: string;
  address: string;
  phone?: string;
  showEdit?: boolean;
  isHighlighted?: boolean;
  onEdit?: () => void;
}

const AddressCard: React.FC<AddressCardProps> = ({
  titleId,
  addressLabelId,
  address,
  phone,
  isHighlighted = false,
  showEdit = false,
  onEdit,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Card.Elevated
      css={{
        padding: '24px',
        boxShadow: 'none',
        borderRadius: '9px',
        border: isHighlighted ? '1px solid #5C6370' : '1px solid #D6D6D6',
        width: '100%',
        maxWidth: '888px',
        backgroundColor: '$surfaceBackgroundPrimary',
        '@media (max-width: 600px)': { maxWidth: '100%' },
      }}
    >
      {titleId && (
        <StackLayout
          orientation="horizontal"
          spacing="$one"
          css={{ justifyContent: 'space-between', marginBottom: '$oneAndHalf' }}
        >
          <ParagraphText
            css={{
              fontSize: '20px',
              fontWeight: '700',
              lineHeight: '28px',
            }}
          >
            {formatMessage({ id: titleId as never })}
          </ParagraphText>
          {showEdit && (
            <Button
              onClick={onEdit}
              priority="tertiary"
              width={{
                '@initial': 'hugContents',
                '@mobile': 'fillContainer',
                '@tablet': 'hugContents',
              }}
              quiet
              css={{
                color: '$interactiveActionPrimary',
                padding: '0 !important',
                marginRight: '$one !important',
                width: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: 'inherit',
                  boxShadow: 'none',
                },
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {formatMessage({ id: 'STR_EDIT' })}
            </Button>
          )}
        </StackLayout>
      )}

      <StackLayout orientation="horizontal" css={{ marginTop: '8px' }}>
        <ParagraphText
          size="xs"
          css={{
            fontSize: '14px',
          }}
        >
          {formatMessage({ id: addressLabelId as never })}
        </ParagraphText>
        <StackLayout>
          <ParagraphText
            css={{
              fontSize: '12px',
              fontWeight: '700',
              lineHeight: '16px',
              textAlign: 'right',
              marginLeft: 'auto',
            }}
          >
            {address}
          </ParagraphText>
          {phone && (
            <ParagraphText
              css={{
                fontSize: '12px',
                fontWeight: '700',
                lineHeight: '16px',
                textAlign: 'right',
                marginLeft: 'auto',
              }}
            >
              {phone}
            </ParagraphText>
          )}
        </StackLayout>
      </StackLayout>
    </Card.Elevated>
  );
};

export default AddressCard;
