import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  Button,
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useProviderRedirect } from 'hooks/use-pcp-provider-redirect.hook';

export interface TwoActionsCardWidgetProps {
  title: string;
  description: string;
  image: string;
  imageAltText: string;
  actions: { title: string; link: string }[];
}

export const TwoActionsCardWidget = ({
  title,
  description,
  image,
  imageAltText,
  actions,
}: TwoActionsCardWidgetProps) => {
  const { constructRedirectUrl } = useProviderRedirect();

  const handleRedirect = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.CARE,
      sub_product_area: 'homepage',
      screen_name: 'homepage',
      detail: 'change pcp button',
    });
    const redirectUrl = constructRedirectUrl();
    window.location.href = redirectUrl;
  };
  return (
    <Card.Elevated
      css={{
        padding: '0',
        boxShadow: '0px 2px 6px 0px #0000001F !important',
        borderRadius: '8px',
        maxWidth: '100%',
        border: 'none',
        backgroundColor: 'inherit',
        alignItems: 'center',
      }}
      imagePosition="end"
    >
      <Card.Image
        image={image}
        imageAlt={imageAltText}
        css={{
          width: '98px',
          height: '98px',
          objectFit: 'cover',
          marginRight: '24px',
        }}
      />
      <Card.Section>
        <HeadingText level="2" size="sm">
          {title}
        </HeadingText>
        <ParagraphText
          css={{
            width: '180px',
            '@mobile': { width: '100%' },
          }}
        >
          {description}
        </ParagraphText>
        <StackLayout
          css={{
            marginTop: '$oneAndHalf',
            a: {
              display: 'flex !important',
              color: 'white !important',
              backgroundColor: '$primaryBackgroundDefault !important',
              padding: '4px 8px !important',
            },
          }}
        >
          {actions[0].title && (
            <Button
              as="a"
              icon="illustrativePhone"
              href={`/openLink?link=tel:${actions[0].title}`}
            >
              {actions[0].title}
            </Button>
          )}
          <Button
            priority="secondary"
            onClick={handleRedirect}
            width={{ '@initial': 'hugContents', '@mobile': 'fillContainer' }}
            css={{
              borderRadius: '24px',
              paddingX: '$one !important',
              marginTop: '$one',
              width: '162px',
              height: '$two',
              display: 'flex',
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: 'inherit',
                color: 'inherit',
                borderColor: 'inherit',
              },
            }}
          >
            {actions[1].title}
          </Button>
        </StackLayout>
      </Card.Section>
    </Card.Elevated>
  );
};
