import React from 'react';
import { Box } from '@leagueplatform/genesis-core';
import { CaptionWithLink } from 'components/caption-with-link.component/caption-with-link.component';
import { PATHS } from 'commons/constants';

export const EmailDisclaimer: React.FC = () => (
  <Box css={{ marginX: '$twoAndHalf' }}>
    <CaptionWithLink
      messageId="STR_EMAIL_NOTIFICATION_DISCLAIMER"
      linkHref={PATHS.EN_TERMS_OF_USE_ROUTE}
      linkTextId="STR_VISIT_OUR_TERMS"
      customStyles={{ color: '$onSurfaceTextSubdued' }}
    />
  </Box>
);
