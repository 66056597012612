// Onboarding asset overrides
import onboardingFeatureHighlightBg from 'assets/feature-background.svg';
// Journey asset overrides
import insuranceClaims from 'assets/insurance-claim.png';
import journeyHeaderEmptyStateHistory from 'assets/journey-header-empty-state-history.svg';
import errorImg from 'assets/error-icon.svg';

// import journeyHeaderBackground from 'assets/journey-header-background.svg';

const JOURNEY_ASSETS = {
  // JOURNEY_HEADER_BACKGROUND_IMAGE: journeyHeaderBackground,
  EMPTY_STATE_HISTORY: journeyHeaderEmptyStateHistory,
};

const ONBOARDING_ASSETS = {
  FEATURE_HIGHLIGHT_BACKGROUND: onboardingFeatureHighlightBg,
};

const WALLET_ASSETS = {
  CLAIMS_ENTRY: insuranceClaims,
  CLAIMS_LIST_EMPTY: errorImg,
};

export const ASSETS_CONFIG = {
  ...JOURNEY_ASSETS,
  ...ONBOARDING_ASSETS,
  ...WALLET_ASSETS,
};
