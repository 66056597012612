import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';

import { SmallVisualCardWidget } from '@leagueplatform/masonry-widgets';
import { TwoActionsCardWidget } from 'components/cards/two-actions-card-widget.component';

export const PCPCustomSectionRenderer = (props: any) => {
  const { heading, items } = props;

  return (
    <>
      <HeadingText
        level="1"
        size="xxl"
        css={{
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '32px',
          letterSpacing: '0.25px',
          textAlign: 'left',
          marginBottom: '$one',
          marginTop: '$two',
        }}
      >
        {heading}
      </HeadingText>
      <StackLayout
        horizontalAlignment="stretch"
        css={{
          gap: '16px',
          a: {
            display: 'block !important',
            color: '$interactiveActionPrimary !important',
            fontWeight: '700 !important',
            '&:focus': {
              outline: 'none !important',
            },
          },
          strong: {
            fontWeight: 'inherit',
          },
        }}
      >
        {items.map((item: any) => {
          if (item.type === 'text_with_small_image') {
            return (
              <SmallVisualCardWidget
                key={item.attributes.title}
                image={item.attributes.image || ''}
                title={item.attributes.title || ''}
                description={item.attributes.description || ''}
                imageAltText={item.attributes.title || 'image'}
              />
            );
          }

          if (item.type === 'two_actions_card') {
            return (
              <TwoActionsCardWidget
                key={item.attributes.heading}
                title={item.attributes.heading || ''}
                description={item.attributes.description || ''}
                image={item.attributes.image || ''}
                imageAltText={item.attributes.title || 'image'}
                actions={item.attributes.items.map((action: any) => ({
                  title: action.title || '',
                  link: action.link || '',
                }))}
              />
            );
          }

          return null;
        })}
      </StackLayout>
    </>
  );
};
