import { useEffect } from 'react';
import {
  StackLayout,
  HeadingText,
  ParagraphText,
  useMediaQuery,
  queryHelpers,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import exitWarning from 'assets/exit-warning.svg';
import { ModalActionButton } from './common/modal-action-button.component';

export interface Props {
  onSubmit: () => void;
}
export const DeleteAccountModal = ({ onSubmit }: Props) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: PRODUCT_AREA.SETTINGS,
      sub_product_area: 'Delete Account',
      screen_name: 'Delete Account',
      detail: 'user views the delete my account screen ',
    });
  }, []);

  return (
    <BaseModalContainer
      title={formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' })}
      footer={
        <StackLayout
          horizontalAlignment="end"
          orientation={{
            '@initial': 'horizontal',
            '@mobile': 'vertical',
          }}
          spacing="$one"
          css={{ flexGrow: 0 }}
        >
          <ModalActionButton
            actionType="cancel"
            labelId="STR_NEVER_MIND"
            isMobile={isMobile}
            cssStyle={{ color: '$secondaryTextDefault', borderRadius: '62px' }}
          />
          <ModalActionButton
            actionType="submit"
            onClick={onSubmit}
            labelId="STR_YES_DELETE_ACCOUNT"
            isMobile={isMobile}
            isDestructive
            cssStyle={{
              borderRadius: '62px',
            }}
          />
        </StackLayout>
      }
    >
      <ImageMessage
        image={exitWarning}
        css={{
          flexGrow: 1,
          padding: '$two $half $five $half',
          textAlign: 'left',
          '@mobile': { textAlign: 'center' },
        }}
      >
        <HeadingText
          level="2"
          size={{
            '@initial': 'lg',
            '@mobile': 'xl',
          }}
          css={{ paddingBlock: '$one', fontWeight: 'bold' }}
        >
          {formatMessage({ id: 'STR_ARE_YOU_SURE' })}
        </HeadingText>
        <ParagraphText>
          {formatMessage({ id: 'STR_DELETE_ACCOUNT_BODY_1' })}
        </ParagraphText>
        <ParagraphText css={{ marginBlock: '$one' }}>
          {formatMessage({ id: 'STR_DELETE_ACCOUNT_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
