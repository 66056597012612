import React from 'react';
import {
  Card,
  HeadingText,
  StackLayout,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { AuthorizationStatusBar } from 'components/authorizations/authorization-status-banner.component';
import { AuthorizationStatus } from 'utils/get-translated-status-label';

export interface AuthorizationServiceCardProps {
  heading: string;
  status: AuthorizationStatus;
  info: {
    key: string;
    value: string;
  }[];
}

export const AuthorizationServiceCard: React.FC<
  AuthorizationServiceCardProps
> = ({ heading, info, status }) => (
  <Card.Elevated
    css={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      boxShadow: 'none',
      borderRadius: '8px',
      backgroundColor: 'inherit',
      border: '1px solid #D6D6D6',
    }}
  >
    <StackLayout
      css={{
        flex: 1,
      }}
    >
      <AuthorizationStatusBar status={status} />
    </StackLayout>
    <HeadingText
      level="4"
      size="xl"
      css={{
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '0.25px',
        textAlign: 'left',
        marginTop: '$one',
        marginBottom: '$two',
      }}
    >
      {heading}
    </HeadingText>
    <StackLayout
      orientation={{ '@initial': 'horizontal', '@mobile': 'vertical' }}
      css={{ gap: '$one', alignItems: 'start' }}
    >
      {info.map(({ key, value }) => (
        <StackLayout
          key={key}
          orientation="vertical"
          css={{
            padding: '$half',
          }}
        >
          <ParagraphText css={{ fontWeight: 700, fontSize: '14px' }}>
            {key}
          </ParagraphText>
          <ParagraphText>{value}</ParagraphText>
        </StackLayout>
      ))}
    </StackLayout>
  </Card.Elevated>
);
