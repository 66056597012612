import React from 'react';
import {
  ParagraphText,
  RadioIndicator,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import AddressCard from './address-card.component';
import AddressCardV2 from './address-card-v2.component';

interface RadioAddressOptionProps {
  id: string;
  name: string;
  value: string;
  isSelected: boolean;
  onSelect: () => void;
  titleId: string;
  addressLabelId: string;
  address: string;
  phone?: string;
}

const RadioAddressOption: React.FC<RadioAddressOptionProps> = ({
  id,
  name,
  value,
  isSelected,
  onSelect,
  titleId,
  addressLabelId,
  address,
  phone,
}) => {
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  return (
    <>
      <StackLayout
        orientation="horizontal"
        spacing="$half"
        css={{ alignItems: 'flex-start', width: '100%' }}
      >
        <RadioIndicator
          name={name}
          id={id}
          value={value}
          checked={isSelected}
          onChange={onSelect}
        />
        <ParagraphText
          css={{
            paddingBottom: '$oneAndHalf',
          }}
        >
          {titleId}
        </ParagraphText>
      </StackLayout>
      {isMobile ? (
        <AddressCardV2 addressLabelId={addressLabelId} address={address} />
      ) : (
        <AddressCard
          addressLabelId={addressLabelId}
          address={address}
          phone={phone}
        />
      )}
    </>
  );
};

export default RadioAddressOption;
