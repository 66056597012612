import React from 'react';
import { Box, ParagraphText, TextAction } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

interface ContactDetailsProps {
  title: string;
  email: string;
  phone: string;
  hours: string;
  variant?: 'default' | 'centered';
}

export const ContactDetails: React.FC<ContactDetailsProps> = ({
  title,
  email,
  phone,
  hours,
  variant = 'default',
}) => {
  const { formatMessage } = useIntl();

  const isCentered = variant === 'centered';

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: isCentered ? 'center' : 'left',
        gap: '4px',
        width: '100%',
      }}
    >
      <ParagraphText
        css={{
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000',
        }}
      >
        {title}
      </ParagraphText>

      <Box
        css={{
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <ParagraphText css={{ fontSize: '14px', paddingRight: '4px' }}>
          {formatMessage({ id: 'STR_EMAIL' })}:
        </ParagraphText>
        <TextAction
          as="a"
          href={`mailto:${email}`}
          css={{
            fontSize: '14px',
            fontWeight: 700,
            textDecoration: 'underline',
            color: '$interactiveActionPrimary',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          {email}
        </TextAction>
      </Box>

      <Box
        css={{
          display: 'flex',
          flexDirection: isCentered ? 'row' : 'column',
          justifyContent: isCentered ? 'center' : 'flex-start',
        }}
      >
        <Box
          css={{
            display: 'flex',
            alignItems: 'start',
          }}
        >
          <ParagraphText css={{ fontSize: '14px', paddingRight: '4px' }}>
            {formatMessage({ id: 'STR_CALL' })}:
          </ParagraphText>
          <TextAction
            as="a"
            href={`/openLink?link=tel:${phone}`}
            css={{
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 700,
              textDecoration: 'underline',
              color: '$interactiveActionPrimary !important',
              '&:focus': {
                outline: 'none',
              },
            }}
          >
            {phone}
          </TextAction>
        </Box>
      </Box>

      <ParagraphText
        css={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#666',
          textAlign: isCentered ? 'center' : 'left',
        }}
      >
        {formatMessage({ id: 'STR_HOURS' })}: {hours}
      </ParagraphText>
    </Box>
  );
};
