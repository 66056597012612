import { Box } from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';

export const AppStoreLink = ({
  href,
  imgSrc,
  altText,
}: {
  href: string;
  imgSrc: string;
  altText: string;
}) => (
  <Box
    as="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    css={{
      display: 'inline-block',
      margin: '5px 0',
    }}
  >
    <Image src={imgSrc} alt={altText} width={158} height={43} />
  </Box>
);
