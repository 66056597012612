import { useCallback } from 'react';

import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';

export const useHandleAccountDeleted = () => {
  trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
    product_area: PRODUCT_AREA.SETTINGS,
    sub_product_area: 'Delete Account',
    screen_name: 'Delete Account',
    details: 'user clicks on acknowledgement CTA ',
  });
  const handleAccountDeleted = useCallback(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
      federated: true,
    });
  }, []);

  return {
    handleAccountDeleted,
  };
};
