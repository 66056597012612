import { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import {
  EditProfilePhoto,
  usePersonalInformation,
  useUpdateUserProfile,
} from '@scfhp-web/shared-module';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { useElementHeight } from 'hooks/use-element-height.hook';
import { FormContent } from 'components/post-authentication/account-setup/form-content.component';
import { FixedFormFooter } from 'components/forms/fixed-form-footer.component';
import { AccountSetupTopSection } from 'components/post-authentication/account-setup/top-section.component';
import { AccountSetupFormValues } from 'components/post-authentication/account-setup/account-setup.types';

export const AccountSetupForm = ({
  onStageChange,
  onBack,
}: {
  onStageChange: () => void;
  onBack: () => void;
}) => {
  const { formatMessage } = useIntl();
  const { data } = usePersonalInformation();

  useEffect(() => {
    trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: 'Account setup page',
      screen_name: 'Account setup page',
      detail: 'Account setup page is loaded',
    });
  }, []);

  const onError = () => {
    toastActions.add({
      type: TOAST_STATUS.ERROR,
      textId: 'STR_DISPLAY_NAME_UPDATE_FAILED',
    });
  };

  const { updateUserProfile } = useUpdateUserProfile({
    onError,
  });

  const onSubmit = async (values: AccountSetupFormValues) => {
    const { preferredFirstName } = values;

    trackAnalyticsEvent('BUTTON_CLICKED', {
      product_area: PRODUCT_AREA.ONBOARDING,
      sub_product_area: 'Account setup page',
      screen_name: 'Account setup page',
      detail: 'continue',
    });

    try {
      await updateUserProfile({ preferredFirstName });

      onStageChange();
    } catch (error) {
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'STR_DISPLAY_NAME_UPDATE_FAILED',
      });
    }
  };

  const { elementRef: footerRef, elementHeight: footerHeight } =
    useElementHeight();

  const initialValues: AccountSetupFormValues = {
    preferredFirstName:
      data?.userProfile?.preferredFirstName ??
      data?.userProfile?.firstName ??
      '',
    isTermsAccepted: false,
    isEmailEnabled: !!data?.userProfile?.optedIntoMarketingCommunications,
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={() => ({})}
      onSubmit={onSubmit}
    >
      {(formProps) => (
        <Form>
          <StackLayout
            horizontalAlignment="center"
            css={{
              maxWidth: 768,
              padding: '$two',
              margin: '$none auto',
              marginBottom: footerHeight,
              '@mobile': { padding: '$oneAndHalf' },
            }}
          >
            <AccountSetupTopSection
              title={formatMessage({ id: 'STR_ACCOUNT_SETUP_TITLE' })}
              description={formatMessage({
                id: 'STR_ACCOUNT_SETUP_DESCRIPTION',
              })}
            />
            <EditProfilePhoto />
            <StackLayout
              spacing="$one"
              horizontalAlignment="stretch"
              css={{ marginTop: '$oneAndHalf' }}
            >
              <FormContent
                setFieldValue={formProps.setFieldValue}
                values={formProps.values}
              />
            </StackLayout>
          </StackLayout>
          <FixedFormFooter
            dirty={formProps?.dirty}
            back={() => onBack()}
            next={formProps.submitForm}
            isLoading={formProps.isSubmitting}
            submitText={formatMessage({ id: 'STR_CONTINUE' })}
            ref={footerRef}
          />
        </Form>
      )}
    </Formik>
  );
};
