import { useLOB, usePersonalInformation } from '@scfhp-web/shared-module';
import { useCallback } from 'react';

interface CustomField {
  name: string;
  value: string;
}

const BASE_URL = import.meta.env.VITE_PCP_BASE_URL;
const CATEGORY = 'Primary+Care+Physician';

export const useProviderRedirect = () => {
  const lob = useLOB();
  const { data: personalInformation } = usePersonalInformation();

  // Extract the mostRecentNetwork value from customFields
  const mostRecentNetworkValue = personalInformation?.customFields?.find(
    (field: CustomField) => field.name === 'mostRecentNetwork',
  )?.value;

  /**
   * Constructs a URL to redirect to the provider directory.
   */
  const constructRedirectUrl = useCallback(() => {
    const RedirectUri = `${window.location.origin}/change-pcp`;

    const params = [
      'Embedded=True',
      `ProviderPlan=${lob}`,
      `Category=${CATEGORY}`,
      `RedirectUri=${encodeURIComponent(RedirectUri)}`,
    ];

    if (lob === 'Medi-Cal' && mostRecentNetworkValue) {
      params.splice(2, 0, `Network=${mostRecentNetworkValue}`);
    }

    return `${BASE_URL}?${params.join('&')}`;
  }, [lob, mostRecentNetworkValue]);

  /**
   * Redirects the user by changing the window location.
   */
  const handleProviderRedirect = useCallback(() => {
    const url = constructRedirectUrl();
    window.location.href = url;
  }, [constructRedirectUrl]);

  /**
   * Extracts PCP details from the current URL's query parameters.
   */
  const extractPCPDetails = useCallback(() => {
    const queryParams = new URLSearchParams(window.location.search);

    return {
      ProviderName: queryParams.get('ProviderName') || 'Unknown',
      Phone: queryParams.get('Phone') || 'No Phone Provided',
      ClinicAddress: queryParams.get('ClinicAddress') || 'No Address Provided',
      ClinicName: queryParams.get('ClinicName') || 'Unknown Clinic',
    };
  }, []);

  return { constructRedirectUrl, handleProviderRedirect, extractPCPDetails };
};
