import { useGetUserProfile } from '@leagueplatform/web-common';
import snakecaseKeys from 'snakecase-keys';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  setUserProfile,
  UserProfile,
} from '@leagueplatform/user-profile-api';
import { useQueryClient, useMutation } from 'react-query';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import { formatDateToLongFormat } from '../utilities/format-date';
import { formatPhoneNumber } from '../utilities/format-phone-number';
import { formatZipCode } from '../utilities/format-zip-code';

interface Arguments {
  onSuccess?: () => void;
  onError?: () => void;
}

export const usePersonalInformation = () => {
  const { data, isLoading, error } = useGetUserProfile();
  const {
    data: firstNameData,
    isLoading: firstNameIsLoading,
    error: firstNameError,
  } = usePreferredFirstName();

  const formatAddress = (location: any) => {
    if (!location) return '';
    return `${location.address1}\n${location.city}, ${
      location.state
    } ${formatZipCode(location.postal)}`;
  };

  const homeAddress = formatAddress(
    data?.userProfile?.locations?.find((loc) => loc.name === 'Home'),
  );
  const mailingAddress = formatAddress(
    data?.userProfile?.locations?.find((loc) => loc.name === 'Mail'),
  );

  const fullName = `${firstNameData?.preferredFirstName?.toUpperCase()} ${
    data?.userProfile.lastName ?? ''
  }`;
  const userInitials = `${firstNameData?.preferredFirstName?.[0] ?? ''}${
    data?.userProfile?.lastName?.[0] ?? ''
  }`;

  const initialValues = {
    preferredFirstName: data?.userProfile?.preferredFirstName || '',
    firstName: data?.userProfile?.firstName || '',
    lastName: data?.userProfile?.lastName || '',
    dateOfBirth: formatDateToLongFormat(data?.userProfile?.dateOfBirth || ''),
    email: data?.email || '',
    phoneNumber: formatPhoneNumber(data?.phoneNumber || ''),
    homeAddress,
    mailingAddress,
  };
  const customFields = (data as any)?.customFields || []; // Temporary fix

  return {
    data: { ...data, fullName, userInitials, initialValues, customFields },
    isLoading: isLoading || firstNameIsLoading,
    error: error ?? firstNameError,
  };
};

/**
 * @name useUpdateUserProfile
 * @desc React hook to update the user profile
 * @returns Function that updates user profile
 */
export const useUpdateUserProfile = ({ onSuccess, onError }: Arguments) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateUserProfileMutation, isLoading } = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]);
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    },
  );

  const updateUserProfile = async (userProfile: any) => {
    const serializedBody = snakecaseKeys(userProfile);
    await updateUserProfileMutation(serializedBody);
  };

  return { updateUserProfile, isLoading };
};
