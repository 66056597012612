import React from 'react';
import { Box, ParagraphText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  AuthorizationStatus,
  getStatusTranslationKey,
} from 'utils/get-translated-status-label';

interface AuthorizationStatusBarProps {
  status: AuthorizationStatus;
}

const statusColors: Record<AuthorizationStatus, string> = {
  approved: '#D5F2E7',
  medReview: '#D9F3F3',
  inProgress: '#FAE8D6',
  denied: '#F9DEE3',
  closed: '#F0F0F0',
};

const statusCircleColors: Record<AuthorizationStatus, string> = {
  approved: '#00A865',
  medReview: '#00BAAE',
  inProgress: '#CF6902',
  denied: '#A12127',
  closed: '#767676',
};

export const AuthorizationStatusBar: React.FC<AuthorizationStatusBarProps> = ({
  status,
}) => {
  const { formatMessage } = useIntl();

  const translatedLabel = formatMessage({
    id: getStatusTranslationKey(status) as never,
  });
  const backgroundColor = statusColors[status];
  const circleColor = statusCircleColors[status];

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        height: '32px',
        padding: '0px 12px',
        gap: '4px',
        borderRadius: '99999px',
        border: '0px',
        backgroundColor,
      }}
    >
      <Box
        css={{
          width: '8px',
          height: '8px',
          backgroundColor: circleColor,
          borderRadius: '50%',
        }}
      />
      {/* Status Label */}
      <ParagraphText
        css={{
          textDecorationSkipInk: 'none',
          whiteSpace: 'pre-line',
          typography: '$bodyTwo',
          fontWeight: 700,
        }}
      >
        {translatedLabel}
      </ParagraphText>
    </Box>
  );
};
