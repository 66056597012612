import React, { useState, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  StackLayout,
  TextAction,
  InputLabel,
  TextInput,
  InputHint,
  Button,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { TOAST_STATUS, toastActions } from '@leagueplatform/toast-messages';
import { useUpdateUserProfile } from '../../../hooks/use-update-user-profile.hook';

interface EditDisplayNameProps {
  displayName: string;
  setDisplayName: (value: string) => void;
}

export const EditDisplayName: React.FC<EditDisplayNameProps> = ({
  displayName,
  setDisplayName,
}) => {
  const { formatMessage } = useIntl();
  const [isFormVisible, setIsFormVisible] = useState(false);

  const editButtonRef = useRef<HTMLButtonElement>(null);

  const closeFormAndFocusEditButton = async () => {
    await setIsFormVisible(false);
    editButtonRef.current?.focus();
  };

  const onSuccess = () => {
    closeFormAndFocusEditButton();
    toastActions.add({
      type: TOAST_STATUS.SUCCESS,
      textId: 'STR_DISPLAY_NAME_UPDATED',
    });
  };

  const onError = () => {
    setDisplayName(displayName);
    toastActions.add({
      type: TOAST_STATUS.ERROR,
      textId: 'STR_DISPLAY_NAME_UPDATE_FAILED',
    });
  };

  const { updateUserProfile, isLoading } = useUpdateUserProfile({
    onSuccess,
    onError,
  });

  const handleSubmit = async () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
      screen_name: SUB_PRODUCT_AREA.PERSONAL_INFO,
      details: 'user saves display name ',
    });
    await updateUserProfile({ preferredFirstName: displayName });
  };

  const handleCancel = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
      screen_name: SUB_PRODUCT_AREA.PERSONAL_INFO,
      details: 'user cancels display name',
    });
    closeFormAndFocusEditButton();
  };

  const handleEditClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
      screen_name: SUB_PRODUCT_AREA.PERSONAL_INFO,
      details: 'user clicks the edit display name button',
    });
    setIsFormVisible(true);
  };

  return (
    <StackLayout
      horizontalAlignment={isFormVisible ? 'stretch' : 'center'}
      css={{
        margin: '$half auto',
      }}
    >
      {isFormVisible ? (
        <>
          <InputLabel
            htmlFor="display-name"
            required
            css={{ marginBottom: '$half' }}
          >
            {formatMessage({ id: 'STR_DISPLAY_NAME' })}
          </InputLabel>
          <TextInput
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            css={{ marginBottom: '$half' }}
            id="display-name"
            name="display-name"
            aria-describedby="display-name-hint"
            autoFocus
          />
          <InputHint id="display-name-hint">
            {formatMessage({ id: 'STR_DISPLAY_NAME_HINT' })}
          </InputHint>
          <StackLayout
            orientation="horizontal"
            horizontalAlignment="spaceBetween"
            verticalAlignment="center"
            css={{ marginTop: '$half' }}
          >
            <TextAction as="button" onClick={handleCancel}>
              {formatMessage({ id: 'STR_CANCEL' })}
            </TextAction>
            <Button onClick={handleSubmit} loading={isLoading}>
              {formatMessage({ id: 'STR_SAVE' })}
            </Button>
          </StackLayout>
        </>
      ) : (
        <>
          <ParagraphText css={{ typography: '$headingThree' }}>
            {displayName}
          </ParagraphText>
          <TextAction ref={editButtonRef} as="button" onClick={handleEditClick}>
            {formatMessage({ id: 'STR_EDIT_DISPLAY_NAME' })}
          </TextAction>
        </>
      )}
    </StackLayout>
  );
};
