import { GDSIconProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import {
  LANG_CHINESE,
  LANG_ENGLISH,
  LANG_SPANISH,
  LANG_TAGALOG,
  LANG_VIETNAMESE,
  LOB_DUAL_CONNECT,
  LOB_MEDI_CAL,
} from '@scfhp-web/shared-module';
import { PATHS } from 'commons/constants';

export interface FooterLinkProps {
  text: string;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

const boldTextLink: GDSStyleObject = {
  fontWeight: 'bold',
};

export const applyNonDescripmantionCustomizations = (
  language: string,
  lob: string,
) => {
  if (lob === LOB_DUAL_CONNECT) {
    if (language === LANG_SPANISH) {
      return PATHS.ES_NON_DESCRIMINARTION_DUALCONNECT;
    }
    if (language === LANG_CHINESE) {
      return PATHS.CN_NON_DESCRIMINARTION_DUALCONNECT;
    }
    if (language === LANG_VIETNAMESE) {
      return PATHS.VT_NON_DESCRIMINARTION_DUALCONNECT;
    }
    if (language === LANG_TAGALOG) {
      return PATHS.TC_NON_DESCRIMINARTION_DUALCONNECT;
    }
    if (language === LANG_ENGLISH) {
      return PATHS.EN_NON_DESCRIMINARTION_DUALCONNECT;
    }
  }

  if (lob === LOB_MEDI_CAL) {
    if (language === LANG_SPANISH) {
      return PATHS.ES_NON_DESCRIMINARTION_MEDICAL;
    }
    if (language === LANG_CHINESE) {
      return PATHS.CN_NON_DESCRIMINARTION_MEDICAL;
    }
    if (language === LANG_VIETNAMESE) {
      return PATHS.VT_NON_DESCRIMINARTION_MEDICAL;
    }
    if (language === LANG_TAGALOG) {
      return PATHS.TG_NON_DESCRIMINARTION_MEDICAL;
    }
    if (language === LANG_ENGLISH) {
      return PATHS.EN_NON_DESCRIMINARTION_MEDICAL;
    }
  }
  return '';
};

export const footerLinksConfig = (
  lob: string,
  language: string,
): FooterLinkProps[] => {
  const baseLinks: FooterLinkProps[] = [
    {
      text: 'STR_TERMS_AND_CONDITIONS',
      url: PATHS.EN_TERMS_OF_USE_ROUTE,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_PRIVACY_POLICY',
      url: PATHS.EN_PRIVACY_POLICY,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_NON_DESCRIMINATORY_NOTICE',
      url: applyNonDescripmantionCustomizations(language, lob),
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_LANGUAGE_ASSISTANCE',
      url:
        lob === LOB_DUAL_CONNECT
          ? PATHS.LANGUAGE_ASSISTANCE_DUALCONNECT
          : PATHS.LANGUAGE_ASSISTANCE_MEDICAL,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_DELEGATION_CONTACT_INFO',
      url: PATHS.DELEGATE_CONTACT_MEDICAL,
      target: '_blank',
      linkProps: boldTextLink,
    },
  ];

  return baseLinks;
};

export const footerSubLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'STR_SCFHP_WEBSITE',
    url: PATHS.WEBSITE,
    target: '_blank',
    linkProps: boldTextLink,
  },
  {
    text: 'STR_FACEBOOK',
    url: PATHS.FACEBOOK,
    target: '_blank',
    linkProps: boldTextLink,
  },
  {
    text: 'STR_LINKEDIN',
    url: PATHS.LINKEDIN,
    target: '_blank',
    linkProps: boldTextLink,
  },
  {
    text: 'STR_CALENDER_OF_EVENTS',
    url: PATHS.EN_CALENDER_OF_EVENTS,
    target: '_blank',
    linkProps: boldTextLink,
  },
];
