import React from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  Image,
  useClickableCard,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { extractPhoneNumber } from 'utils/extract-phone-number';

interface CustomerServiceCardProps {
  title: string;
  description: string;
  actionLink: string;
  body: string;
  icon: string;
}

export const CustomerServiceCard: React.FC<CustomerServiceCardProps> = ({
  title,
  description,
  actionLink,
  body,
  icon,
}) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  const { formatMessage } = useIntl();

  return (
    <>
      <HeadingText
        level="2"
        size="xl"
        css={{
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '32px',
          letterSpacing: '0.25px',
          textAlign: 'left',
          marginBottom: '$one',
          marginTop: '$three',
        }}
      >
        {formatMessage({ id: 'STR_GET_HELP_NOW' })}
      </HeadingText>
      <Card.Elevated
        css={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: 'none',
          borderRadius: '8px',
          boxShadow: '0px 2px 6px 0px #0000001F !important',
        }}
      >
        <Card.ClickArea
          ref={clickAreaRef}
          onCardMouseUp={onCardMouseUp}
          onCardMouseDown={onCardMouseDown}
          imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
          css={{
            flexDirection: 'row',
            alignItems: 'center',
            border: 'none !important',
            padding: '$two',
          }}
        >
          <Image
            src={icon}
            alt="Customer Service"
            css={{
              width: '42px',
              height: '42px',
              marginRight: '12px',
            }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          />
          <StackLayout
            css={{
              flex: 1,
            }}
          >
            <HeadingText
              level="3"
              size="lg"
              css={{ typography: '$subtitleOne', marginBottom: '4px' }}
            >
              {title}
            </HeadingText>
            <TextAction as="a" href={actionLink} ref={primaryActionRef}>
              <ParagraphText
                css={{ typography: '$bodyTwo', textDecoration: 'underline' }}
              >
                {extractPhoneNumber(actionLink)}
              </ParagraphText>
            </TextAction>

            <ParagraphText css={{ typography: '$bodyTwo' }}>
              {description}
            </ParagraphText>
            <ParagraphText
              css={{ whiteSpace: 'pre-line', typography: '$bodyTwo' }}
            >
              {body}
            </ParagraphText>
          </StackLayout>
        </Card.ClickArea>
      </Card.Elevated>
    </>
  );
};
