import React from 'react';
import { StackLayout, UtilityText } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  EVENT_NAME,
  PRODUCT_AREA,
  SUB_PRODUCT_AREA,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { CustomerInfoChangeForm } from '../../common/constants';

export const MakeUpdateCard = () => {
  const { formatMessage } = useIntl();

  const handleLinkClick = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      sub_product_area: SUB_PRODUCT_AREA.PERSONAL_INFO,
      screen_name: SUB_PRODUCT_AREA.PERSONAL_INFO,
      details: 'user clicks on edit contact information link',
    });
  };
  return (
    <StackLayout
      css={{
        paddingTop: '$two',
      }}
    >
      <UtilityText css={{ typography: '$bodyOne' }}>
        {`${formatMessage({ id: 'STR_SUNMIT_A_REQUEST' })} `}
        <a
          href={`/health-journey/campaign/${CustomerInfoChangeForm.campaignId}/activity/${CustomerInfoChangeForm.activityId}/toolbox`}
          style={{ fontWeight: 400 }}
          onClick={handleLinkClick}
        >
          {formatMessage({ id: 'STR_MEMBER_CONTACT_INFORMATION_CHANGE' })}
        </a>
        {` ${formatMessage({ id: 'STR_FORM' })}`}.
      </UtilityText>
    </StackLayout>
  );
};
