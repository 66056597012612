import React from 'react';
import {
  ParagraphText,
  RadioIndicator,
  StackLayout,
} from '@leagueplatform/genesis-core';
import FormFields from './pcp-form-fields.component';

interface RadioAddressFormOptionProps {
  id: string;
  name: string;
  value: string;
  titleId: string;
  isSelected: boolean;
  onSelect: () => void;
  formModel: Array<any>;
  formAddress: Record<string, string>;
  onFormChange: (field: string, value: string) => void;
}

const RadioAddressFormOption: React.FC<RadioAddressFormOptionProps> = ({
  id,
  name,
  value,
  titleId,
  isSelected,
  onSelect,
  formModel,
  formAddress,
  onFormChange,
}) => {
  const handleInputChange = (field: string, val: string) => {
    if (!isSelected) {
      onSelect();
    }
    onFormChange(field, val);
  };

  return (
    <>
      <StackLayout
        orientation="horizontal"
        spacing="$half"
        css={{ alignItems: 'flex-start', width: '100%', marginTop: '$two' }}
      >
        <RadioIndicator
          name={name}
          id={id}
          value={value}
          checked={isSelected}
          onChange={onSelect}
        />
        <ParagraphText
          css={{
            paddingBottom: '$oneAndHalf',
          }}
        >
          {titleId}
        </ParagraphText>
      </StackLayout>

      <FormFields
        formModel={formModel}
        formData={formAddress}
        isSelected={isSelected}
        onFieldChange={handleInputChange}
      />
    </>
  );
};

export default RadioAddressFormOption;
