import { useState, useEffect } from 'react';
import { useGetUserProfile } from '@leagueplatform/web-common';
import { SocketAsFetch } from '@leagueplatform/socket-as-fetch';
import { isMobileUserAgent } from 'utils/is-mobile-user-agent';

export const useOnboarding = () => {
  const { data, isLoading: isUserProfileLoading } = useGetUserProfile();
  const [stage, setStage] = useState(() =>
    isMobileUserAgent()
      ? 'completed'
      : sessionStorage.getItem('onboardingStage') || 'featureHighlights',
  );
  const [isLoading, setLoading] = useState(true);
  const [onboardingCompleted, setOnboardingCompleted] = useState(
    () =>
      isMobileUserAgent() ||
      sessionStorage.getItem('onboardingCompleted') === 'true',
  );

  useEffect(() => {
    if (isMobileUserAgent()) {
      setStage('completed');
      setOnboardingCompleted(true);
      sessionStorage.setItem('onboardingStage', 'completed');
      sessionStorage.setItem('onboardingCompleted', 'true');
      setLoading(false);
      return;
    }

    if (!isUserProfileLoading) {
      const savedStage = sessionStorage.getItem('onboardingStage');
      const completed =
        sessionStorage.getItem('onboardingCompleted') === 'true';

      if (completed) {
        setStage('completed');
        setOnboardingCompleted(true);
        setLoading(false);
        return;
      }

      if (savedStage) {
        setStage(savedStage);
        setLoading(false);
        return;
      }

      const hasUserAcceptedTerms =
        data?.userProfile?.memberTermsAndConditions?.dateAccepted || false;

      if (hasUserAcceptedTerms) {
        setStage('completed');
        setOnboardingCompleted(true);
        sessionStorage.setItem('onboardingStage', 'completed');
        sessionStorage.setItem('onboardingCompleted', 'true');
        setLoading(false);
        return;
      }

      setStage('featureHighlights');
      sessionStorage.setItem('onboardingStage', 'featureHighlights');
      setLoading(false);
    }
  }, [data, isUserProfileLoading]);

  const onFeatureHighlightComplete = () => {
    setStage('accountSetup');
    sessionStorage.setItem('onboardingStage', 'accountSetup');
  };

  const onAccountSetupComplete = () => {
    setStage('allSet');
    sessionStorage.setItem('onboardingStage', 'allSet');
  };

  const onFirstStep = () => {
    setStage('featureHighlights');
    sessionStorage.setItem('onboardingStage', 'featureHighlights');
  };

  const onAllSetComplete = async () => {
    await SocketAsFetch.fetch({
      message_type: 'accept_member_terms',
      info: {
        version: 2,
      },
    });
    setStage('completed');
    setOnboardingCompleted(true);
    sessionStorage.setItem('onboardingStage', 'completed');
    sessionStorage.setItem('onboardingCompleted', 'true');
  };

  return {
    stage,
    isLoading,
    onboardingCompleted,
    onFirstStep,
    onFeatureHighlightComplete,
    onAccountSetupComplete,
    onAllSetComplete,
  };
};
