import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  ParagraphText,
} from '@leagueplatform/genesis-core';

interface EmailDetailsProps {
  email: string;
}

export const EmailDetails: React.FC<EmailDetailsProps> = ({ email }) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout orientation="vertical" css={{ paddingX: '$twoAndHalf' }}>
      <StackLayout
        orientation="vertical"
        spacing="$half"
        horizontalAlignment="stretch"
        css={{ marginY: '$one' }}
      >
        <HeadingText level="2" size="xs" css={{ lineHeight: '20px' }}>
          {formatMessage({ id: 'STR_APP_NOTIFICATIONS' })}
        </HeadingText>
        <ParagraphText css={{ fontSize: '14px', lineHeight: '20px' }}>
          {email}
        </ParagraphText>
        <ParagraphText
          css={{
            fontSize: '12px',
            lineHeight: '16px',
            a: { fontSize: 'inherit', fontWeight: 'inherit' },
          }}
        >
          {formatMessage({ id: 'STR_USER_NOTIFIED_EMAIL_SUBTITLE' })}
        </ParagraphText>
      </StackLayout>
    </StackLayout>
  );
};
