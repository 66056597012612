import { FC } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { StackLayout, ParagraphText } from '@leagueplatform/genesis-core';
import { useCommonPageSetup } from '@scfhp-web/shared-module';
import { HeroSection } from 'components/hero-section/hero-section.component';

interface AboutUsPageProps {}

export const AboutUsPage: FC<AboutUsPageProps> = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: 'STR_ABOUT_US' });
  const history = useHistory();

  useCommonPageSetup({ title, details: 'user views the about app page' });

  return (
    <MainPageContainer width="100%">
      <HeroSection title={title} goBack={history.goBack} />
      <StackLayout css={{ paddingTop: '134px', maxWidth: '750px' }}>
        <ParagraphText
          css={{
            fontFamily: '$bodyOne',
            strong: { fontWeight: '700' },
          }}
        >
          {formatMessage({ id: 'STR_ABOUT_US_CONTENT' })}
        </ParagraphText>
      </StackLayout>
    </MainPageContainer>
  );
};
