import React, { useEffect } from 'react';
import { StackLayout, Modal } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useHistory } from '@leagueplatform/routing';
import { useCommonPageSetup } from '@scfhp-web/shared-module';
import { BackButtonArrow } from '@leagueplatform/web-common-components';
import { isMobileUserAgent } from 'utils/is-mobile-user-agent';
import { FixedFormFooter } from 'components/forms/fixed-form-footer.component';
import { SubmitPCPModal } from 'components/modals/submit-pcp-modal.component';
import { useChangePCP } from 'hooks/use-change-pcp.hook';
import PCPReviewStage from 'components/pcp/PCP-review-stage.component';
import AddressSelection from 'components/pcp/address-selection.component';
import FinalConfirmation from 'components/pcp/final-confirmation.component';

export const ChangePCPPage = () => {
  const { formatMessage } = useIntl();
  const isMobileDevice = isMobileUserAgent();
  const history = useHistory();
  useCommonPageSetup({ title: 'Review change PCP page' });

  const {
    pcps,
    isLoading,
    isModalOpen,
    toggleModal,
    step,
    handleNext,
    handleBack,
    mailingAddress,
    addressData,
  } = useChangePCP();

  // Scroll to top whenever the step changes
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [step]);

  const renderStage = () => {
    switch (step) {
      case 1:
        return (
          <PCPReviewStage
            title={formatMessage({
              id: 'STR_REVIEW_INFORMATION',
            })}
            pcps={pcps}
          />
        );
      case 2:
        return (
          <AddressSelection
            selectedOption={mailingAddress.isSameAddress ? 'listed' : 'custom'}
            currentAddress={mailingAddress.currentAddress}
            currentPhoneNumber={mailingAddress.currentPhoneNumber}
            formAddress={mailingAddress.newAddress}
            onOptionChange={(option) =>
              mailingAddress.confirmAddress(option === 'listed')
            }
            onFormChange={mailingAddress.handleNewAddressChange}
            title={formatMessage({
              id: 'STR_REVIEW_INFORMATION',
            })}
            subTitle={formatMessage({
              id: 'STR_CONFIRM_MAILING_ADDRESS',
            })}
          />
        );
      case 3:
        return (
          <FinalConfirmation
            title={formatMessage({
              id: 'STR_CONFIRM_YOUR_MAILING_ADDRESS',
            })}
            addressData={addressData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <StackLayout
      css={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
        padding: '32px',
        gap: '32px',
        '@mobile': {
          padding: '40px 0',
        },
      }}
    >
      {!isMobileDevice && (
        <StackLayout
          css={{
            position: 'absolute',
            left: '5%',
            top: '$half',
          }}
        >
          <BackButtonArrow
            fontSize="heading3"
            color="onSurface.text.primary"
            marginRight="one"
            marginY="half"
            justifyContent="initial"
            onClick={handleBack}
          />
        </StackLayout>
      )}
      {renderStage()}

      <StackLayout
        css={{
          width: '100%',
          padding: '0 32px',
          boxSizing: 'border-box',
        }}
      >
        <FixedFormFooter
          back={isMobileDevice ? handleBack : () => history.push('/get-care')}
          next={handleNext}
          isLoading={isLoading}
          submitText={formatMessage({
            id: 'STR_CONTINUE',
          })}
          returnText={formatMessage({
            id: isMobileDevice ? 'STR_BACK' : 'STR_CANCEL',
          })}
          customStyle={{
            maxWidth: '952px',
            margin: '0 auto',
          }}
        />
      </StackLayout>

      <Modal.Root open={isModalOpen} onOpenChange={toggleModal}>
        <SubmitPCPModal />
      </Modal.Root>
    </StackLayout>
  );
};

export default ChangePCPPage;
