import React from 'react';
import {
  Box,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import HeaderSection from './header-section.component';
import AddressCardV2 from './address-card-v2.component';
import AddressCard from './address-card.component';

interface AddressData {
  id: string;
  titleId: string;
  addressLabelId: string;
  address: string;
  showEdit?: boolean;
  onEdit?: () => void;
}

interface FinalConfirmationProps {
  addressData: AddressData[];
  title: string;
}

const FinalConfirmation: React.FC<FinalConfirmationProps> = ({
  addressData,
  title,
}) => {
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));

  return (
    <Box
      css={{
        maxWidth: '952px',
        width: '100%',
        margin: '0 auto',
        boxShadow: '0px 2px 6px 0px #0000001F',
        overflow: 'hidden',
        padding: '32px',
        '@mobile': {
          padding: '0.7em',
          boxShadow: 'none',
        },
      }}
    >
      <HeaderSection title={title} />
      <StackLayout
        css={{
          gap: isMobile ? '$one' : '$two',
          maxWidth: '800px',
          margin: '$two auto',
        }}
      >
        {addressData.map((item, index) =>
          isMobile ? (
            <AddressCardV2
              key={item.id}
              titleId={item.titleId}
              addressLabelId={item.addressLabelId}
              address={item.address}
              showEdit={item.showEdit}
              onEdit={item.onEdit}
              isHighlighted={index === 1}
            />
          ) : (
            <AddressCard
              key={item.id}
              titleId={item.titleId}
              addressLabelId={item.addressLabelId}
              address={item.address}
              showEdit={item.showEdit}
              onEdit={item.onEdit}
              isHighlighted={index === 1}
            />
          ),
        )}
      </StackLayout>
    </Box>
  );
};
export default FinalConfirmation;
