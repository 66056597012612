import {
  StackLayout,
  Button,
  HeadingText,
  ParagraphText,
  Box,
} from '@leagueplatform/genesis-core';
import { Image } from '@leagueplatform/genesis-commons';
import { FullScreenContainer } from 'components/full-screen-container/full-screen-container.component';
import { ErrorTypes, useErrorMessage } from 'hooks/use-error-message.hook';
import errorIcon from 'assets/not-eligible-screen.svg';
import { useIntl } from '@leagueplatform/locales';
import { NOT_ELIGIBLE_CONTACT } from 'commons/constants';
import { ContactDetailsList } from 'components/contact-details/contact-details-list.component';

export const NotEligibleScreen = ({
  errorType,
  backLink,
}: {
  errorType: ErrorTypes;
  backLink?: string;
}) => {
  const { errorHeading, errorCaption } = useErrorMessage(errorType);
  const { formatMessage } = useIntl();

  const formattedContactDetails = NOT_ELIGIBLE_CONTACT.map((item) => ({
    ...item,
    title: formatMessage({ id: `STR_${item.title.toUpperCase()}` }),
  }));

  return (
    <FullScreenContainer>
      <StackLayout
        orientation="vertical"
        horizontalAlignment="center"
        verticalAlignment="center"
        css={{
          height: '100%',
          whiteSpace: 'pre-wrap',
          maxWidth: '489px',
          margin: '0 auto',
        }}
      >
        <Image
          src={errorIcon}
          alt=""
          width="138px"
          height="auto"
          position="relative"
          top="minusOne"
          marginBottom="three"
        />
        <HeadingText
          level="1"
          size="xl"
          css={{
            marginBottom: '$half',
            textAlign: 'center',
          }}
        >
          {errorHeading}
        </HeadingText>
        <ParagraphText
          emphasis="subtle"
          css={{ textAlign: 'center', marginBottom: '20px' }}
        >
          {errorCaption}
        </ParagraphText>
        <Box css={{ display: 'flex' }}>
          <ContactDetailsList items={formattedContactDetails} />
        </Box>
        <Box
          css={{
            width: '265px',
            marginTop: '$oneAndHalf',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            css={{
              marginTop: '$half',
              color: '$secondaryTextDefault',
              borderRadius: '30px',
              '&:hover': {
                // backgroundColor: 'transparent',
                color: 'inherit',
              },
              width: '244px',
              display: 'flex',
              justifyContent: 'center',
            }}
            as="a"
            href={backLink || '/sign-out'}
            size="medium"
            priority="secondary"
          >
            {formatMessage({ id: 'STR_CLOSE' })}
          </Button>
        </Box>
      </StackLayout>
    </FullScreenContainer>
  );
};
