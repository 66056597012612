import { useEffect } from 'react';
import { captureAppError } from '@leagueplatform/observability';
import { fetchDataWithToken, useLOB } from '@scfhp-web/shared-module';
import { useProviderRedirect } from './use-pcp-provider-redirect.hook';

export function shouldIntercept(
  target: HTMLElement,
  pathToIntercept: string,
): boolean {
  // const href = target.getAttribute('href');
  return target.getAttribute('href')?.includes(pathToIntercept) || false;
}

export function useInterceptLinkClick(
  pathToIntercept: string,
  rootUrl: string,
): void {
  const lob = useLOB();
  const { constructRedirectUrl } = useProviderRedirect();

  useEffect(() => {
    const handleClick = async (event: MouseEvent) => {
      let element = event.target as HTMLElement;

      while (element && element.tagName !== 'A' && element.parentElement) {
        element = element.parentElement;
      }

      if (element.tagName === 'A') {
        const href = element.getAttribute('href');
        if (!href) return;

        try {
          const absoluteHref = new URL(href, rootUrl).href;

          if (href.includes('openLink?link=tel')) {
            event.preventDefault();
            event.stopPropagation();

            const url = new URL(href, rootUrl);
            const encodedTelLink = url.searchParams.get('link');
            if (encodedTelLink) {
              let telLink = decodeURIComponent(encodedTelLink);

              if (!telLink.startsWith('tel:') && telLink.startsWith('tel')) {
                telLink = telLink.replace('tel', 'tel:');
              }

              if (telLink.startsWith('tel:')) {
                window.location.href = telLink;
              }
            }
            return;
          }

          if (href.includes('openLink?link=segmentation')) {
            event.preventDefault();
            event.stopPropagation();

            const url = new URL(href, rootUrl);

            const phoneNumber = url.searchParams.get(lob);

            if (phoneNumber) {
              const decodedTelLink = decodeURIComponent(phoneNumber);

              if (decodedTelLink.startsWith('tel:')) {
                window.location.href = decodedTelLink;
              }
            }

            return;
          }

          // New check for SSO link
          if (href.includes('openLink?link=sso&path=provider-redirect')) {
            event.preventDefault();
            event.stopPropagation();

            const redirectUrl = constructRedirectUrl();
            window.location.href = redirectUrl;
            return;
          }

          if (shouldIntercept(element, pathToIntercept)) {
            event.preventDefault();
            event.stopPropagation();

            const newTab = window.open('', '_blank');
            if (newTab) {
              newTab.document.write(
                '<html><head><title>Loading...</title></head><body></body></html>',
              );
              newTab.document.close();
            }

            fetchDataWithToken(absoluteHref)
              .then((htmlContent: any) => {
                if (newTab) {
                  newTab.document.open();
                  newTab.document.write(htmlContent);
                  newTab.document.close();
                }
              })
              .catch((error: any) => {
                captureAppError(error);
                if (newTab) {
                  newTab.document.open();
                  newTab.document.write('<p>Error loading content</p>');
                  newTab.document.close();
                }
              });

            return;
          }
        } catch (error: any) {
          captureAppError(error);
        }
      }
    };

    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [pathToIntercept, rootUrl, lob, constructRedirectUrl]);
}
