import { useEffect, useState } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { Spinner } from '@leagueplatform/genesis-core';
import { useHistory, useLocation } from '@leagueplatform/routing';
import {
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';

export const AuthViewLogout = () => {
  // Logout using the most recently used auth config.

  useEffect(() => {
    StandaloneAuth.client
      .logout({
        returnTo: window.location.origin,
        federated: localStorage.getItem('AUTH_CONFIG') !== 'IMPERSONATION', // Impersonation does not support federated logout, as it uses IdP initiated SAML
      })

      .then(() => {
        localStorage.setItem('AUTH_CONFIG', 'MEMBER'); // Set the auth config back to member so that the landing page doesn't break
      });
  }, []);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  // When the user attempts to access the application via the "member login" route, check if the auth config is correctly set.
  // If not, set it and then reload the application so that the correct auth config is used.
  if (localStorage.getItem('AUTH_CONFIG') !== 'MEMBER') {
    localStorage.setItem('AUTH_CONFIG', 'MEMBER');
    window.location.reload();
  }

  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect({
      prompt: 'login',
      appState: { invalidateSession: true },
      redirectMethod: 'replace',
    });
  }, []);

  return <LoadingIndicator />;
};

// Gets auth0 error returned from redirect, the returned error could be a query param or a hash param
// const getAuth0ErrorIfExists = (location: Location) => {
//   const searchParams = new URLSearchParams(location.search);
//   const hashParams = new URLSearchParams(location.hash.replace(/#/g, '?'));

//   const type = searchParams.get('error') || hashParams.get('error');
//   const description =
//     searchParams.get('error_description') ||
//     hashParams.get('error_description');

//   if (type) {
//     return { type, description };
//   }

//   return null;
// };

export const AuthStartImpersonationSession = () => {
  const location = useLocation();
  const history = useHistory();

  const [error, setError] = useState('');
  const AUTH0_CLIENT_CONNECTION = 'scfhp-internal-enterprise-saml';

  // When the user attempts to access the application via the "impersonation login" route, check if the auth config is correctly set.
  // If not, set it and then reload the application so that the correct auth config is used.
  if (localStorage.getItem('AUTH_CONFIG') !== 'IMPERSONATION') {
    localStorage.setItem('AUTH_CONFIG', 'IMPERSONATION');
    window.location.reload();
  }

  useEffect(() => {
    const connection =
      new URLSearchParams(location.search).get('connection') ||
      AUTH0_CLIENT_CONNECTION;

    // const authError = getAuth0ErrorIfExists(location);
    // if (authError) {
    // setError(`${authError.type}: ${authError.description || ''}`);
    // captureAuthenticationError(authError);
    //   return;
    // }

    try {
      const options = {
        connection,
      };

      StandaloneAuth.client.loginWithRedirect(options);
    } catch (e: unknown) {
      // to do add logging
      // logger.error(e);
      setError(e instanceof Error ? e.message : String(e));
    }
  }, [location, history]);

  if (error) {
    return <ErrorState bodyString={error} hideButton />;
  }
  return <Spinner />;
};
