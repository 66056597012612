export const formatDateToLongFormat = (dateStr: string) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const datePart = dateStr.split('T')[0];

  const [year, month, day] = datePart.split('-');

  return `${months[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
};
