// useCommonPageSetup.js
import { useDocumentTitle } from '@leagueplatform/web-common';
import { usePageViewAnalytics, PRODUCT_AREA } from '@leagueplatform/analytics';

interface CommonPageSetupParams {
  title: string;
  productArea?: string;
  details?: string;
}

export const useCommonPageSetup = ({
  title,
  productArea = PRODUCT_AREA.SETTINGS,
  details,
}: CommonPageSetupParams) => {
  useDocumentTitle(title);
  usePageViewAnalytics({
    product_area: productArea,
    screen_name: title,
    details,
  });
};
